import React, {useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { gsap } from "gsap";
import "../css/home.css";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import LogoL from "../images/LogoL.png";
import LogoS from "../images/LogoS.png";

const backgroundStyle = {
    width: 'calc()',
    height: '700px',
    backgroundColor: '#fffff',
    marginBottom:'calc(50px + 5vh + 5vw)', 
    // backgroundColor:'grey',
    // overflowX: "hidden !important",
    position: "relative",
}

const Home = () => {
    

    useEffect(() => {
        let tl = gsap.timeline();
        const logAnimation = () => {
            gsap.set(".logo", {autoAlpha: 1});
            gsap.set("#logoLine", {autoAlpha: 0.4});
            tl.fromTo("#logoLine",{width: "25%" , autoAlpha: 0},{width: "50%", autoAlpha: 0.4, ease: "power1.out", duration:"0.9"}) 
            .fromTo(".logo",{right:"-20%", autoAlpha: 0}, {right:"0%", autoAlpha: 1, ease: "power1.out", duration:"0.9"}, "-=0.8");
        }
        const hide = () => {
            gsap.set(".logo", {autoAlpha: 0});
            gsap.set("#logoLine", {autoAlpha: 0});
        }
        const mountLogoAnimation = () => {
            gsap.registerPlugin(ScrollTrigger);
            ScrollTrigger.create({
                trigger: "#logoContainer",
                onEnter: logAnimation,
                onEnterBack: logAnimation,
                onLeave: hide
            });
        }
        mountLogoAnimation();
    }, []);


    return (
        <Grid container id="logoContainer" style={backgroundStyle} justifyContent="flex-end">
            <div id="logoWrapper">
                <img id="logoL" src={LogoL} className="logo" alt=''></img>
                <img id="logoS" src={LogoS} className="logo" alt=''></img>
            </div>
            
            {/* <h1 id="logo">RISEUP INC.</h1> */}
            <div id="logoLine"></div>
        </Grid>
    )
}

export default Home